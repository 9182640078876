import React, { useCallback, useRef, useEffect, useState } from 'react';

import { IconType } from 'react-icons/lib';
import { Link, useParams } from 'react-router-dom';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import api from '../../services/api';
import { date, hour, dateLanguage, simpleDate } from '../../utils/date';
import { Container } from './styles';
import { addHours } from 'date-fns';
import { useToast } from '../../hooks/Toast';
import { Form } from '@unform/web';

import { string } from 'yup';

import { useLanguage } from '../../hooks/Language';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import Favorite from '../../components/ClassRoomContainer/modules/Favorite';
import { useAuth } from '../../hooks/Auth';
import { useConfiguration } from '../../hooks/Configuration';
import { defaultProjectHash, hash } from '../../config';
import { loadFromJson } from '../../utils/loadFromJson';
import Input from '../../components/Forms/Input';
import { useLoading } from '../../hooks/LoadingHook';
import Axios from 'axios';

interface IOption {
  value: string;
  label: string;
}

interface IProgram {
  date: Date;
  formatted_date?: string;
  start_hour: Date;
  formatted_start_hour?: string;
  end_date: Date;
  formatted_end_date?: string;
  title: string;
  category: string;
  room_reference_id_response: Record<string, any>;
  description: string;
}

interface IProgramFilter {
  date: Date;
  formatted_date: string;
  program: Array<IProgram>;
  simpleDate: string;
  day_reference?: string;
  category?: string;
  room_name?: string;
}

interface IProps {
  filterCategory?: string;
  removeCategory?: string;
}
const ListarMonitores: React.FC<IProps> = () => {
  const { projectHash = defaultProjectHash } = useParams<Record<string, any>>();
  const [paperStorage, setPaperStorage] = useState<Array<Record<string, any>>>([]);
  const [search, setSearch] = useState('');
  const { addLoading, removeLoading } = useLoading();
  const papers = search === '' ? paperStorage : paperStorage?.filter(item => {

    const searchItem = ` ${item?.nome} ${item?.sessao} ${item?.data} ${item?.horario} ${item?.salaPredio}  `.toLowerCase();

    return searchItem?.indexOf(search?.trim()?.toLowerCase()) >= 0;

  })



  const load = async () => {
    addLoading({ title: 'Carregando', key: 'loading' })
    const response = await Axios.get(`/apoio/monitores.json`);

    if (response?.data) {

      setPaperStorage(response?.data);
    }
    removeLoading('loading')
  }

  useEffect(() => {

    if (projectHash) {
      load();
    }


  }, [projectHash])

  const handleSearch = (data) => {

    setSearch(data?.search);

  }

  return (


    <Container id="news" style={{ background: '#fff' }}>
      <h1 style={{ color: '#1d436f', fontSize: '24px', textAlign: 'center', marginTop: '40px', marginBottom: '20px' }}>{'Horários dos monitores'} </h1>

      <Form onSubmit={handleSearch} style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px' }}>

        <Input
          style={{ borderRadius: '10px', minWidth: '200px' }}
          name="search"
          placeholder="Pesquise Aqui"
          type="text"
        />

        <button className="buttonBigGreen" style={{ borderRadius: '10px', maxWidth: '120px' }} type="submit">Pesquisar</button>

      </Form>

      <div >
        <table className='table'>

          <tbody>
            {paperStorage?.length < 1 ? <tr><td>Carregando...</td></tr> : papers?.map(paper => {
              return <tr key={paper?._id?.toString()}>
                <td>
                  {paper?.nome ? <p title='Nome' style={{ color: '#333' }}><strong style={{ color: '#333' }}>Monitor:</strong> {paper?.nome}</p> : <></>}

                  {paper?.sessao ? <p title='Sala e Seção' style={{ color: '#333' }}><strong style={{ color: '#333' }}>Sala - Prédio:</strong> {paper?.salaPredio} | <strong style={{ color: '#333' }}>Seção:</strong> {paper?.sessao}</p> : <></>}
                  {paper?.data ? <p style={{ color: '#333' }}><strong style={{ color: '#333' }}>Data:</strong> {paper?.data} - {paper?.horario}</p> : <></>}

                </td>
              </tr>
            })}


          </tbody>
        </table>
      </div>



    </Container >
  );
};
export default ListarMonitores;
