import React, { useEffect, useState } from 'react';
import Button from '../../../../../../components/Forms/Button';
import Submission from '../Submission';
import { Container } from './styles';
import MyPapers from '../MyPapers';
import MyPapersOrientator from '../MyPapersOrientator';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { authTitle, supportNumber } from '../../../../../../config';
import Whatsapp from '../../../../../../components/Whatsapp';
import UpdateProfile from '../../../../../../components/FormContainerUpdate';
import ListSubmission from '../ListSubmission';
interface IParams {
  tab: string;
}

const CientificHome: React.FC = () => {
  const [type, setType] = useState('profile');
  const { tab } = useParams<IParams>();

  const [validTab, setValidTab] = useState({
    home: '',
    submeter: 'submeter'
  });

  useEffect(() => {

    if (tab && validTab[tab]) {
      setType(validTab[tab]);
    }

  }, [tab])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>

      <Container >

        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center', padding: '10px' }}>
          <Link to="/trabalhos"><Button onClick={() => setType('profile')}>Minha inscrição</Button></Link>
          <Link to="/trabalhos"><Button onClick={() => setType('')}>Meus trabalhos</Button></Link>
          <Link to="/trabalhos"><Button onClick={() => setType('orientator')}>Sou orientador</Button></Link>
          <Link to="/trabalhos/submeter"><Button onClick={() => setType('submeter')}>Submeter trabalho</Button></Link>

          <a download href="/apoio/2024_regulamento_X_Mostra_Ensino_Pesquisa_Extensao.pdf">
            <Button  >Regulamento</Button>

          </a>
          <a download href="/apoio/2024_Mostra_Ensino_Pesquisa_Extensao_UFCSPA.pptx">
            <Button  >Template PPT</Button>

          </a>

          <Whatsapp number={supportNumber} text={`${authTitle}: Quero submeter um trabalho`} message="Suporte Técnico" />

        </div>

        {type === '' && <MyPapers />}
        {type === 'orientator' && <MyPapersOrientator />}
        {type === 'submeter' && <ListSubmission />}
        {type === 'submeter-trabalho' && <Submission />}
        {type === 'profile' && <UpdateProfile />}

      </Container>
    </>
  );
};
export default CientificHome;
