import { Form } from '@unform/web';
import React, { useState } from 'react';
import { FaArrowCircleRight } from 'react-icons/fa';
import TextareaInput from '../../../../../../components/Forms/TextareaInput';
import { useConfiguration } from '../../../../../../hooks/Configuration';
import { useModal } from '../../../../../../hooks/Modal';
import { useToast } from '../../../../../../hooks/Toast';
import api from '../../../../../../services/api';
import Loader from '../../../../core/components/Loader';
import { Separator } from '../../pages/Submission/styles';

import { Container } from './style';
interface IProps {
    data: Record<string, any>;
    submission: Record<string, any>;
    submissionId: string;
}

const ReviewOrientatiorApproval: React.FC<IProps> = ({ data, submission, submissionId }) => {
    const [loading, setLoading] = useState(false);
    const { addToast } = useToast();
    const { configuration } = useConfiguration();
    const { addModal } = useModal();
    const renderField = (block, sub) => {

        const hashField = block?.hash;
        if (hashField === 'categories') {

            //  return <CheckboxIncluder category={category} area={area} subarea={subarea} setSubarea={(value) => setSubarea(value)} setArea={(value) => setArea(value)} setCategory={(value) => setCategory(value)} name="category" options={sub?.categories} placeholder="Categorias" />
            return <>
                <tr><td>Categoria</td><td>{data.category}</td></tr>
                <tr><td>Área</td><td>{data.area}</td></tr>
                <tr><td>SubÁrea</td><td>{data.subarea}</td></tr>
            </>


        }
        else if (hashField === 'resume') {

            return <>
                <tr><td>Título</td><td>{data?.title}</td></tr>
                <tr><td>Resumo</td><td><p dangerouslySetInnerHTML={{ __html: data?.short_description }} /></td></tr>
                <tr><td>Palavras-chave</td><td>{data?.nested_keywords}</td></tr>
            </>

        }
        else if (hashField === 'extra_categories') {

            return <tr><td>{block?.title}</td><td>{data?.extra_categories?.join(', ')}</td></tr>

        }
        else if (hashField === 'coauthor') {

            return <tr><td>{block?.title}</td><td>{data?.nested_co_authors}</td></tr>


        }
        else if (hashField === 'language') {

            return <tr><td>{block?.title}</td><td>{data?.language ? 'Sim' : 'Não'}</td></tr>


        }
        else if (sub?.[`${hashField}_fields`]) {
            const items: Array<JSX.Element> = [];
            sub?.[`${hashField}_fields`]?.map(field => {
                items.push(<tr><td>{field.title}</td><td>{data?.[field?.hash]}</td></tr>)
            });

            return <>{items}</>;

        }



    }

    const renderBlock = (block, number) => {


        return <Separator>
            <label>{number} - {block?.title}</label>

            <table>
                <tbody>
                    {renderField(block, submission)}
                </tbody>
            </table>


        </Separator>


    }


    const send = async (submissionInfo, option) => {
        setLoading(true);

        try {
            await api.post(`/send-orientator-approval/${submissionId?.toString()}/${option}`);
            setLoading(false);
            window?.location?.reload();
        }
        catch (err) {
            setLoading(false);
            addToast({ title: 'Erro ao aprovar', type: 'error' });
        }


    }


    const sendCorrection = async (data) => {
        setLoading(true);

        try {
            await api.post(`/send-orientator-approval/${submissionId?.toString()}/Correção`, data);
            setLoading(false);
            addModal({ title: '', content: <h2 style={{ color: '#333' }}> Solicitação de correção enviada para o participante. </h2>, theme: 'whiteModalMini' })
        }
        catch (err) {
            console.log(err)
            setLoading(false);
            addToast({ title: 'Erro ao aprovar', type: 'error' });
        }


    }

    return <Container>
        {loading ? <Loader message='Carregando...' /> : <></>}
        <h2 style={{ color: '#333', width: '100%', textAlign: 'center' }}>{configuration?.title}</h2>
        <h2 style={{ color: '#333', width: '100%', textAlign: 'center' }}>Validação pelo orientador</h2>
        <p style={{ color: '#333', width: '100%', textAlign: 'center' }}>Prezado orientador, solicitamos que valide a submissão do trabalho abaixo, para que ele possa ser aceito junto ao evento.</p>
        <br />
        <button onClick={() => send(data, 'Sim')} className="defaultButtonReverse">APROVAR</button>
        <button onClick={() => send(data, 'Não')} className="defaultButtonReverse">REJEITAR</button>

        <br />
        {submission?.allow_edit === 'yes' ? <>
            <p style={{ color: '#333', width: '100%', textAlign: 'center' }}>Caso deseje, você pode solicitar alterações ao seu orientando.</p>


            {data?.orientator_correction ? <p style={{ textAlign: 'center', marginTop: '30px', border: '1px solid #ddd', borderRadius: '5px', padding: '15px', color: '#333', width: '100%', maxWidth: '800px', marginBottom: '30px' }}><strong style={{ color: '#333' }}>Última correção solicitada:</strong><br />{data?.orientator_correction}</p> : <></>}

            <Form onSubmit={sendCorrection}>
                <TextareaInput name='orientator_correction' placeholder='Orientações para correção' />

                <button style={{ margin: '10px auto' }} className="defaultButtonReverse">SOLICITAR CORREÇÃO</button>
            </Form>
        </> : <></>}



        {submission?.available_blocks?.map((available, index) => {
            return renderBlock(available, index + 1);
        })}


        <button onClick={() => send(data, 'Sim')} className="defaultButtonReverse">APROVAR</button>
        <button onClick={() => send(data, 'Não')} className="defaultButtonReverse">REJEITAR</button>




    </Container>
}

export default ReviewOrientatiorApproval;