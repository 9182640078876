import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';
import template from './template/BlockRender';
import viewAnaisOnModal from './modules/ViewAnaisOnModal';
import uploadAvaliatorConfirmations from './modules/uploadAvaliatorConfirmations'
interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface IParams {
  projectHash: string;
  eventHash: string;
}

const EventsAdmin: React.FC = () => {
  const endpoint = '/cientific-selection';
  const title = 'Processos de Submissão Científica';

  const { projectHash, eventHash } = useParams<IParams>();

  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    hash: { ref: 'hash', column: 'hash', label: 'Hash' },
    project_id: { ref: 'project_id', column: 'project_id', label: 'Projeto' },
    title: { ref: 'title', column: 'title', label: 'Nome do artigo' },
    description: { ref: 'description', column: 'description', label: 'Nome do evento' },

    start_at: { ref: 'start_at', column: 'start_at', label: 'Data de Início da Submissão' },
    end_at: { ref: 'end_at', column: 'end_at', label: 'Encerramento da Submissão' },


    quantity: { ref: 'quantity', column: 'quantity', label: 'Número máximo de trabalhos' },
    quantity_by_user: { ref: 'quantity_by_user', column: 'quantity_by_user', label: 'Número máximo de trabalhos por usuário?' },

    available_blocks: { ref: 'available_blocks', column: 'available_blocks', label: 'Blocos disponíveis', show: false },

    author_fields: { ref: 'author_fields', column: 'author_fields', label: 'Campos de autor', show: false },
    orientator_fields: { ref: 'orientator_fields', column: 'orientator_fields', label: 'Campos de orientador', show: false },
    coauthor_fields: { ref: 'coauthor_fields', column: 'coauthor_fields', label: 'Campos de coautor', show: false },
    resume_fields: { ref: 'resume_fields', column: 'resume_fields', label: 'Campos de resumo', show: false },
    category_fields: { ref: 'category_fields', column: 'category_fields', label: 'Campos de categorias', show: false },
    extra_fields: { ref: 'extra_fields', column: 'extra_fields', label: 'Campos de categoria extra', show: false },
    award_fields: { ref: 'award_fields', column: 'award_fields', label: 'Campos de premiação', show: false },
    language_fields: { ref: 'language_fields', column: 'language_fields', label: 'Campos de Língua', show: false },


    have_author: { ref: 'have_author', column: 'have_author', label: 'Campo de autor', show: false },
    have_orientator: { ref: 'have_orientator', column: 'have_orientator', label: 'Campo de orientador', show: false },
    have_keywords: { ref: 'have_keywords', column: 'have_keywords', label: 'Campo de palavras-chave?', show: false },
    have_categories: { ref: 'have_categories', column: 'have_categories', label: 'Tem categorias?', show: false },
    have_extra: { ref: 'have_extra', column: 'have_extra', label: 'Tem categorias extras?', show: false },

    have_award: { ref: 'have_award', column: 'have_award', label: 'Tem premiação?', show: false },
    have_award_link: { ref: 'have_award_link', column: 'have_award_link', label: 'Tem premiação link de video para premiação?', show: false },

    have_language: { ref: 'have_language', column: 'have_language', label: 'Tem apresentação em inglês?', show: false },

    categories: { ref: 'categories', column: 'categories', label: 'Categorias', show: false },
    extra_categories: { ref: 'extra_categories', column: 'extra_categories', label: 'Categorias Adicionais', show: false },
    extra_categories_limit: { ref: 'extra_categories_limit', column: 'extra_categories_limit', label: 'Limite de categorias adicionais', show: false },



    createdAt: {
      ref: 'createdAt',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date', show: false
    },
  };

  const formSchema = {

    title: { model: 'input', type: 'text', name: 'title', label: 'Nome do evento' },
    hash: { model: 'input', type: 'text', name: 'hash', label: 'Hash' },
    description: { model: 'richText', type: 'text', name: 'description', label: 'Descritivo' },

    start_at: { model: 'datePickerHour', type: 'text', name: 'start_at', label: 'Data de Início da Submissão' },
    end_at: { model: 'datePickerHour', type: 'text', name: 'end_at', label: 'Data de Encerramento da Submissão' },

    quantity: { model: 'input', type: 'text', name: 'quantity', label: 'Límite máximo de trabalhos', defaultValue: 800 },
    quantity_by_user: { model: 'input', type: 'text', name: 'quantity_by_user', label: 'Límite máximo de trabalhos por usuário', defaultValue: 1 },

    quantity_exceptions: {
      model: 'ArrayInputIncluder',
      name: 'quantity_exceptions',
      label: 'CPFs em excessão de quantidade',

    },

    available_blocks: {
      model: 'jsonInputList',
      type: 'text',
      name: 'available_blocks',
      label: 'Blocos disponíveis',
      show: 'title',
      list: [
        { label: 'Hash', name: 'hash', type: 'input' },
        { label: 'Título', name: 'title', type: 'input' },
        { label: 'Título para seleção do avaliador', name: 'avaliator_title', type: 'input' },


        {
          label: 'Ativo?', name: 'status', type: 'select', options: [
            { label: 'Sim', text: 'Sim', value: 'yes' },
            { label: 'Não', text: 'Não', value: 'no' },
          ]
        },

      ],
      defaultValue: [
        { hash: 'author', title: 'Dados do Autor', status: 'yes' },
        { hash: 'orientator', title: 'Dados do Orientador', status: 'yes' },
        { hash: 'coauthor', title: 'Demais autores', status: 'yes' },
        { hash: 'resume', title: 'Submissão do trabalho', status: 'yes' },
        { hash: 'categories', title: 'Categoria de submissão', status: 'yes' },
        { hash: 'extra_categories', title: 'Liste até 3 ODS que tenham afinidade com o seu trabalho', status: 'yes' },
        { hash: 'language', title: 'Apresentação em inglês', status: 'yes' },
        { hash: 'award', title: 'Gostaria de concorrer ao Prêmio Divulgação Científica?', status: 'yes' },
      ]
    },

    author_fields: {
      model: 'jsonInputList',
      type: 'text',
      name: 'author_fields',
      label: 'Campos de autor',
      show: 'title',
      list: [
        { label: 'Hash', name: 'hash', type: 'input' },
        { label: 'Título', name: 'title', type: 'input' },
        { label: 'Descritivo', name: 'description', type: 'richText' },

        {
          label: 'Tipo', name: 'type', type: 'select', options: [

            { label: 'Texto', text: 'Texto', value: 'input' },
            { label: 'Área de Texto', text: 'Área de Texto', value: 'textarea' },
            { label: 'Seleção', text: 'Seleção', value: 'select' },
            { label: 'Caixa de seleção', text: 'Caixa de seleção', value: 'checkbox' },

          ]
        },
        { label: 'Opções de seleção', name: 'options', type: 'arrayIncluder' },
        {
          label: 'Obrigatório?', name: 'mandatory', type: 'select', options: [
            { label: 'Sim', text: 'Sim', value: 'yes' },
            { label: 'Não', text: 'Não', value: 'no' },
          ]
        },

      ],
      defaultValue: [
        { hash: 'author', title: 'Nome do autor', type: 'input', mandatory: 'yes' },
        { hash: 'author_email', title: 'E-mail autor', type: 'input', mandatory: 'yes' },
        { hash: 'author_institution', title: 'Instituição do autor', type: 'input', mandatory: 'yes' },
        { hash: 'author_document_number', title: 'CPF', type: 'input', mandatory: 'yes' },

      ]
    },

    orientator_fields: {
      model: 'jsonInputList',
      type: 'text',
      name: 'orientator_fields',
      label: 'Campos de orientador',
      show: 'title',
      list: [
        { label: 'Título', name: 'title', type: 'input' },
        { label: 'Descritivo', name: 'description', type: 'richText' },

        {
          label: 'Tipo', name: 'type', type: 'select', options: [

            { label: 'Texto', text: 'Texto', value: 'input' },
            { label: 'Área de Texto', text: 'Área de Texto', value: 'textarea' },
            { label: 'Seleção', text: 'Seleção', value: 'select' },
            { label: 'Caixa de seleção', text: 'Caixa de seleção', value: 'checkbox' },

          ]
        },
        { label: 'Opções de seleção', name: 'options', type: 'arrayIncluder' },
        {
          label: 'Obrigatório?', name: 'mandatory', type: 'select', options: [
            { label: 'Sim', text: 'Sim', value: 'yes' },
            { label: 'Não', text: 'Não', value: 'no' },
          ]
        },

      ],
      defaultValue: [
        { hash: 'orientator', title: 'Nome do orientador', type: 'input', mandatory: 'yes' },
        { hash: 'orientator_email', title: 'E-mail orientador', type: 'input', mandatory: 'yes' },
        { hash: 'orientator_institution', title: 'Instituição do orientador', type: 'input', mandatory: 'yes' },
        { hash: 'orientator_document_number', title: 'CPF do orientador', type: 'input', mandatory: 'yes' },

      ]
    },

    coautor_fields: {
      model: 'jsonInputList',
      type: 'text',
      name: 'coautor_fields',
      label: 'Campos de coautor',
      show: 'title',
      list: [
        { label: 'Título', name: 'title', type: 'input' },
        { label: 'Descritivo', name: 'description', type: 'richText' },

        {
          label: 'Tipo', name: 'type', type: 'select', options: [

            { label: 'Texto', text: 'Texto', value: 'input' },
            { label: 'Área de Texto', text: 'Área de Texto', value: 'textarea' },
            { label: 'Seleção', text: 'Seleção', value: 'select' },
            { label: 'Caixa de seleção', text: 'Caixa de seleção', value: 'checkbox' },

          ]
        },
        { label: 'Opções de seleção', name: 'options', type: 'arrayIncluder' },
        {
          label: 'Obrigatório?', name: 'mandatory', type: 'select', options: [
            { label: 'Sim', text: 'Sim', value: 'yes' },
            { label: 'Não', text: 'Não', value: 'no' },
          ]
        },

      ],
      defaultValue: [
        { hash: 'name', title: 'Nome completo', type: 'input', mandatory: 'yes' },
        { hash: 'email', title: 'E-mail', type: 'input', mandatory: 'yes' },
        { hash: 'institution', title: 'Instituição', type: 'input', mandatory: 'yes' },
        { hash: 'document_number', title: 'CPF', type: 'input', mandatory: 'yes' },

      ]
    },

    resume_fields: {
      model: 'jsonInputList',
      type: 'text',
      name: 'resume_fields',
      label: 'Campos de resumo',
      show: ['title'],
      list: [
        { label: 'Hash', name: 'hash', type: 'input' },
        { label: 'Título', name: 'title', type: 'input' },
        { label: 'Descritivo', name: 'description', type: 'richText' },
        { label: 'Limite de caracteres', name: 'limit', type: 'input' },
        {
          label: 'Tipo', name: 'type', type: 'select', options: [

            { label: 'Texto', text: 'Texto', value: 'input' },
            { label: 'Área de Texto', text: 'Área de Texto', value: 'textarea' },
            { label: 'Seleção', text: 'Seleção', value: 'select' },
            { label: 'Caixa de seleção', text: 'Caixa de seleção', value: 'checkbox' },
            { label: 'Lista adicionável', text: 'Lista adicionável', value: 'array_includer' },
            { label: 'Lista em modal', text: 'Lista em modal', value: 'jsonInputList' },
          ]
        },
        { label: 'Opções de seleção', name: 'options', type: 'arrayIncluder' },
        {
          label: 'Obrigatório?', name: 'mandatory', type: 'select', options: [
            { label: 'Sim', text: 'Sim', value: 'yes' },
            { label: 'Não', text: 'Não', value: 'no' },
          ]
        },

      ],
      defaultValue: [
        { hash: 'title', title: 'Título', type: 'input', mandatory: 'yes' },
        { hash: 'short_description', title: 'Resumo', type: 'richText', mandatory: 'yes' },
        { hash: 'keywords', title: 'Palavras-chave', type: 'array_includer', mandatory: 'yes' },


      ]
    },

    categories: {
      model: 'jsonInputList',
      type: 'text',
      name: 'categories',
      label: 'Categorias disponíveis',
      show: 'title',
      list: [
        { label: 'Título', name: 'title', type: 'input' },
        { label: 'Descritivo', name: 'description', type: 'richText' },


        {
          label: 'Opções de seleção', name: 'options', type: 'jsonInputList', show: ['title'], list: [
            { label: 'Título', name: 'title', type: 'input' },
            { label: 'Descritivo', name: 'description', type: 'richText' },

            { label: 'Opções de seleção', name: 'options', type: 'arrayIncluder' },



          ]
        },


      ]
    },

    extra_categories_fields: {
      model: 'jsonInputList',
      type: 'text',
      name: 'extra_categories_fields',
      label: 'Categorias extras',
      show: 'title',
      list: [
        { label: 'Título', name: 'title', type: 'input' },
        { label: 'Descritivo', name: 'description', type: 'richText' },
        { label: 'Limite de seleções', name: 'limit', type: 'input' },

        {
          label: 'Opções de seleção', name: 'options', type: 'jsonInputList', list: [
            { label: 'Título', name: 'title', type: 'input' },
            { label: 'Descritivo', name: 'description', type: 'richText' },

            { label: 'Opções de seleção', name: 'options', type: 'arrayIncluder' },



          ]
        },


      ]
    },

    language_fields: {
      model: 'jsonInputList',
      type: 'text',
      name: 'language_fields',
      label: 'Língua',
      show: ['title'],
      list: [
        { label: 'Hash', name: 'hash', type: 'input' },
        { label: 'Título', name: 'title', type: 'input' },
        { label: 'Descritivo', name: 'description', type: 'richText' },

        {
          label: 'Tipo', name: 'type', type: 'select', options: [

            { label: 'Texto', text: 'Texto', value: 'input' },
            { label: 'Área de Texto', text: 'Área de Texto', value: 'textarea' },
            { label: 'Seleção', text: 'Seleção', value: 'select' },
            { label: 'Caixa de seleção', text: 'Caixa de seleção', value: 'checkbox' },
            { label: 'Lista adicionável', text: 'Lista adicionável', value: 'array_includer' },

          ]
        },
        { label: 'Opções de seleção', name: 'options', type: 'arrayIncluder' },
        {
          label: 'Obrigatório?', name: 'mandatory', type: 'select', options: [
            { label: 'Sim', text: 'Sim', value: 'yes' },
            { label: 'Não', text: 'Não', value: 'no' },
          ]
        },

      ],
      defaultValue: [
        { hash: 'language', title: 'Desejo realizar a minha apresentação oral em inglês', type: 'checkbox', mandatory: 'no', options: ['Sim'] },


      ]
    },

    award_fields: {
      model: 'jsonInputList',
      type: 'text',
      name: 'award_fields',
      label: 'Língua',
      show: 'title',
      list: [
        { label: 'Hash', name: 'hash', type: 'input' },
        { label: 'Título', name: 'title', type: 'input' },
        { label: 'Descritivo', name: 'description', type: 'richText' },

        {
          label: 'Tipo', name: 'type', type: 'select', options: [

            { label: 'Texto', text: 'Texto', value: 'input' },
            { label: 'Área de Texto', text: 'Área de Texto', value: 'textarea' },
            { label: 'Seleção', text: 'Seleção', value: 'select' },
            { label: 'Caixa de seleção', text: 'Caixa de seleção', value: 'checkbox' },
            { label: 'Lista adicionável', text: 'Lista adicionável', value: 'array_includer' },

          ]
        },
        { label: 'Opções de seleção', name: 'options', type: 'arrayIncluder' },
        {
          label: 'Obrigatório?', name: 'mandatory', type: 'select', options: [
            { label: 'Sim', text: 'Sim', value: 'yes' },
            { label: 'Não', text: 'Não', value: 'no' },
          ]
        },
        {
          label: 'Regras de Obrigatoriedade', name: 'mandatory_if', type: 'jsonInputList', list: [
            { label: 'Coluna', name: 'column', type: 'input' },
            { label: 'Valor', name: 'value', type: 'input' },




          ]
        },
      ],
      defaultValue: [
        { hash: 'award', title: 'Você deseja participar do Prêmio Destaque Científico?', type: 'checkbox', options: ['Sim', 'Não'], mandatory: 'yes' },
        { hash: 'award_link', title: 'Insira o link do seu vídeo do youtube', type: 'input', mandatory_if: [{ column: 'award', value: 'Sim' }] },


      ]
    },

    status: {
      alias: 'status',
      model: 'selectSimple',
      name: 'status',
      label: 'Ativo?',
      options: [
        { label: 'Sim', text: 'Sim', value: 'yes' },
        { label: 'Não', text: 'Não', value: 'closed' },
      ]

    },
    allow_edit: {
      alias: 'allow_edit',
      model: 'selectSimple',
      name: 'allow_edit',
      label: 'Permitir Edição',
      options: [
        { label: 'Sim', text: 'Sim', value: 'yes' },
        { label: 'Não', text: 'Não', value: 'no' },
      ]

    },
    allow_avaliate: {
      alias: 'allow_avaliate',
      model: 'selectSimple',
      name: 'allow_avaliate',
      label: 'Permitir Avaliação',
      options: [
        { label: 'Sim', text: 'Sim', value: 'yes' },
        { label: 'Não', text: 'Não', value: 'no' },
      ]

    },
    send_to_orientator: {
      alias: 'send_to_orientator',
      model: 'selectSimple',
      name: 'send_to_orientator',
      label: 'Solicitar aprovação para o orientador?',
      options: [
        { label: 'Sim', text: 'Sim', value: 'yes' },
        { label: 'Não', text: 'Não', value: 'no' },
      ]

    },

    avaliator_title: { model: 'input', type: 'text', name: 'avaliator_title', label: 'Título da área de avaliador' },
    avaliator_categories: {
      model: 'jsonInputList',
      type: 'text',
      name: 'avaliator_categories',
      label: 'Categorias de avaliadores',
      show: ['title'],
      list: [

        { label: 'Categoria', name: 'title', type: 'input' },
      ],

    },

    avaliator_ask_lattes: {
      alias: 'avaliator_ask_lattes',
      model: 'selectSimple',
      name: 'avaliator_ask_lattes',
      label: 'Solicitar Lattes para o avaliador?',
      options: [
        { label: 'Sim', text: 'Sim', value: 'yes' },
        { label: 'Não', text: 'Não', value: 'no' },
      ]

    },

    avaliator_is_orientating: { model: 'input', type: 'text', name: 'avaliator_is_orientating', label: 'Texto para questionar se o avaliador é orientador' },


    avaliator_hour_fields: {
      model: 'jsonInputList',
      type: 'text',
      name: 'avaliator_hour_fields',
      label: 'Horários para avaliadores',
      show: ['title'],
      list: [
        { label: 'Número mínimo de horários', name: 'minimun', type: 'input' },
        { label: 'Horários', name: 'options', type: 'arrayIncluder' },
      ],

    },

    monitor_hour_fields: {
      model: 'jsonInputList',
      type: 'text',
      name: 'monitor_hour_fields',
      label: 'Horários para monitores',
      show: ['title'],
      list: [
        { label: 'Número mínimo de horários', name: 'minimun', type: 'input' },
        { label: 'Horários', name: 'options', type: 'arrayIncluder' },
      ],

    },

    allowed_document_numbers: {
      model: 'ArrayInputIncluderClear',
      type: 'text',
      name: 'allowed_document_numbers',
      label: 'CPFs Permitidos',



    },

  };

  const formSchemaUpdate = {


    ...formSchema



  };

  const formValidation: Record<string, ValidationSchema> = {

  };

  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),

      (lineData: Record<string, any>) => viewAnaisOnModal({ lineData }),
      (lineData: Record<string, any>) => uploadAvaliatorConfirmations({ lineData })
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default EventsAdmin;
