
import React, { useEffect, useState } from 'react';
import { FaCalendar, FaCalendarCheck, FaCalendarDay, FaMapMarker } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import IframePlayer from '../../../../components/IframePlayer';
import { scrollTo } from '../../../../components/Menu';
import { urlWebsite } from '../../../../config';
import { useConfiguration } from '../../../../hooks/Configuration';
import { useLanguage } from '../../../../hooks/Language';
import {

    Capa, Container,



} from '../Themes/SinglePageTheme/styles';

const EdicoesAnteriores = () => {
    const { configuration, projectHash } = useConfiguration();
    const { translate } = useLanguage();

    const [theme, setTheme] = useState({
        titleColor: configuration?.current_event_id_response?.theme?.title_color || '#fff',
        textColor: configuration?.current_event_id_response?.theme?.text_color || '#fff',
        buttonColor: configuration?.current_event_id_response?.theme?.button_color || '#333',
        buttonBackground: configuration?.current_event_id_response?.theme?.button_background || '#3FB8EB',
    })


    useEffect(() => {
        setTheme({
            titleColor: configuration?.current_event_id_response?.theme?.title_color || '#fff',
            textColor: configuration?.current_event_id_response?.theme?.text_color || '#fff',
            buttonColor: configuration?.current_event_id_response?.theme?.button_color || '#333',
            buttonBackground: configuration?.current_event_id_response?.theme?.button_background || '#3FB8EB',
        })
    }, [configuration])


    return <>
        <div className='gradient2' style={{ width: '100%' }}>

            <Container className='capa-no-padding'>

                <Capa style={{ flexDirection: 'row', justifyContent: 'center', gap: '0px' }}>




                    <div className='header column column-start no-padding' style={{
                        minWidth: 'auto',
                        maxWidth: '500px', display: 'flex', borderRadius: '15px', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '0px  20px'
                    }}>
                        <div className='column column-start gap-sm' style={{ gap: '40px' }}>

                            <div className='space-between gap-sm full-width'>

                                <p className='color-white medium'>EDIÇÕES ANTERIORES</p>
                                <p className='color-white medium'>/ /</p>
                            </div>


                            <div className='row-to-column gap-sm full-width' style={{ alignItems: 'center' }}>

                                <div className='row' style={{ minWidth: '150px' }}>
                                    <img src="/apoio/publico.png" style={{ width: '140px' }} />
                                </div>
                                <p className='color-white medium midTitle2'>Mais de <strong>50 mil participantes</strong> em todas as edições</p>

                            </div>

                            <div className='row-to-column gap-sm full-width' style={{ alignItems: 'center' }}>

                                <div className='row' style={{ minWidth: '150px' }}>
                                    <img src="/apoio/speaker.png" style={{ width: '75px' }} />
                                </div>
                                <p className='color-white medium midTitle2'><strong>Dezenas</strong> de <strong>palestrantes</strong> nacionais e internacionais</p>

                            </div>
                        </div>
                    </div>
                    <div className=' column column-start ' style={{ padding: '0px', maxWidth: '500px' }} >

                        <img src="/apoio/auditorio.png" className='full-width' />

                    </div>



                </Capa>


            </Container >
        </div>


        <div style={{ width: '100%', background: '#fff' }}>




            <Container  >
                <Capa style={{ flexDirection: 'row' }}>




                    <div className='header ' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '60vh', padding: '50px', width: '100%' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', justifyContent: 'center', gap: '40px' }}>

                            {/* <div style={{ maxWidth: '800px', width: '100%' }}>
                                <IframePlayer id='https://www.youtube.com/embed/ZIYYOvRJv0U?si=CjiVXHTka_JL3MjN' />

                            </div>
                */}
                            <h2 className='color1 text-center'>SAIBA COMO FORAM AS EDIÇÕES ANTERIORES</h2>
                            <div style={{ display: 'flex', marginTop: '15px', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'center', gap: '15px', width: '100%' }}>
                                <a style={{ width: '100%', maxWidth: '150px' }} href="https://www.sesirs.org.br/sesi-conecta-saude-2021" target='_BLANK'>

                                    <button className='greenButtonBig bold full-width'>2021</button>
                                </a>
                                <a style={{ width: '100%', maxWidth: '150px' }} href="https://www.sesirs.org.br/sesi-conecta-saude-2022" target='_BLANK'>
                                    <button className='greenButtonBig bold full-width'>2022</button>

                                </a>
                                <a style={{ width: '100%', maxWidth: '150px' }} href="https://www.sesirs.org.br/sesi-conecta-saude-2023" target='_BLANK'>
                                    <button className='greenButtonBig bold full-width'>2023</button>
                                </a>


                            </div>


                        </div>
                    </div>




                </Capa>


            </Container>
        </div>

    </>
}

export default EdicoesAnteriores;