import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle, FiX } from 'react-icons/fi';
import { useField, FormHandles } from '@unform/core';
import { Container } from './styles';
import { Error } from '../styles';
import { FaChevronDown, FaChevronUp, FaPlus, FaTrash, FaUpload } from 'react-icons/fa';
import { uuid } from 'uuidv4';
import DatePicker from '../DatePicker';
import { Form } from '@unform/web';
import UploadImage from '../UploadImage';
import Button from '../Button';
import { useModal } from '../../../../../../hooks/Modal';
import { useToast } from '../../../../../../hooks/Toast';
import api from '../../../../../../services/api';
interface IListInput {
  label: string;
  name: string;
  value?: string;
}

interface IInputProps {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  setCallback?: Function;
  formRef?: React.RefObject<FormHandles>;
  list?: Array<IListInput>;
  placeholder: string;
  type?: string;
  label?: string;
}

const ArrayInputIncluderClear: React.FC<IInputProps> = ({
  name,
  icon: Icon,
  formRef,
  setCallback = () => { },
  list,
  label,
  type = 'text',
  ...rest
}): JSX.Element => {
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const { addModal, removeModal } = useModal();
  const { addToast } = useToast();

  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocus, setIsFocus] = useState(false);
  const [isFilled, setIsFilled] = useState(!!defaultValue);


  const [value, setNewValue] = useState(defaultValue || [type === 'text' ? '' : new Date()]);

  const handleInputBlur = useCallback(() => {
    setIsFocus(false);
    setIsFilled(!!inputRef.current?.value);
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocus(true);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: undefined,
      getValue: (ref: any) => {
        return JSON.parse(ref.value);
      },
      setValue: (ref: any, valueN: string) => {
        setNewValue(valueN ? JSON.parse(valueN) : [type === 'text' ? '' : new Date()]);
      },
    });
  }, [fieldName, registerField]);

  const addInput = () => {
    const newHash = `q${new Date().getMilliseconds()}`;
    setNewValue([...value, '']);
  }

  const goUp = index => {
    const indexup = index - 1;

    if (value[indexup]) {
      const newCurrent = [...value];

      newCurrent[index] = value[indexup];
      newCurrent[indexup] = value[index];

      setNewValue([...newCurrent]);

    }
  };

  const goDown = index => {
    const indexup = index + 1;

    if (value[indexup]) {
      const newCurrent = [...value];

      newCurrent[index] = value[indexup];
      newCurrent[indexup] = value[index];

      setNewValue([...newCurrent]);
    }
  };



  const removeItem = (index) => {

    const item = [...value];
    item.splice(index, 1);

    setNewValue([...item]);

  }


  const uploadContacts = async (data, dataItems, type = 'add') => {

    const formData = new FormData();
    formData.append('file', data.file);

    const dataList = await api.post(`/converter/xlsx-to-json`, formData);

    const listItems: Array<string> = [];

    dataList?.data?.rows?.map(i => {

      const campo = i?.CAMPO ? i?.CAMPO?.trim() : '';

      if (campo) {
        console.log(campo)
        listItems.push(campo.toString().replace(/[^0-9]/g, ''));
      }

    })

    if (type === 'add') {
      setNewValue([...dataItems, ...listItems])
    }


    removeModal('uploadContacts');


  }

  const clearHandle = async (): Promise<void> => {
    setNewValue([])
  }

  const handleUpdate = async (dataItems): Promise<void> => {


    const content = (
      <Form onSubmit={(data) => uploadContacts(data, dataItems, 'add')}>

        <UploadImage name="file" placeholder='Excel com CPFs' />
        <Button>Enviar</Button>
      </Form>
    );
    if (content) {
      const keyModal = addModal({ title: 'Upload de vendas', content, key: 'uploadContacts', theme: "whiteModalMini" });
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <label>{label}</label>

      <div className='row'>
        <button type='button' className="greenButton2" onClick={addInput}>
          <FaPlus /> Adicionar
        </button>

        <button type='button' className="greenButton2" onClick={() => handleUpdate(value)}>
          <FaUpload />
        </button>

        <button type='button' className="greenButton2" onClick={clearHandle}>
          <FiX />
        </button>
      </div>
      <input
        ref={inputRef}
        style={{ display: 'none' }}
        name={name}
        value={JSON.stringify(value)}
      />


      {value && value.map((v, index) => {

        return (
          <Container
            key={uuid()}
            {...rest}
            isErrored={!!error}
            isFilled={isFilled}
            isFocused={isFocus}
          >
            <label>{label}</label>

            {type === 'text' ? <input
              placeholder={v.label}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              defaultValue={v}
              onChange={e => {
                const newValue = [...value];


                newValue[index] = e.target.value;



                setNewValue([...newValue]);
              }
              }
              name={uuid()}
            /> : <Form onSubmit={() => { }} initialData={{ [`date-${index}`]: value[index] }}>
              <DatePicker name={`date-${index}`} setCallback={(dateValue) => {

                const newValue = [...value];

                newValue[index] = dateValue;



                setNewValue([...newValue]);


              }} /></Form>}
            {error && (
              <Error title={error}>
                <FiAlertCircle />
              </Error>
            )}
            <div className='listModules'>
              <div><FaChevronUp onClick={() => goUp(index)} style={{ cursor: 'pointer' }} /></div>
              <div><FaChevronDown onClick={() => goDown(index)} style={{ cursor: 'pointer' }} /></div>
              <div><FaTrash onClick={() => removeItem(index)} style={{ cursor: 'pointer' }} /></div>
            </div>
          </Container>
        );
      })}

    </div>
  );
};

export default ArrayInputIncluderClear;
