import React from 'react';
import { useSearch } from '../../../../../../../hooks/Search';
import { useAuth } from '../../../../../../../hooks/Auth';

interface ILine {
  [key: string]: string;
}
interface IParams {
  projectHash: string;
  eventHash: string;
}

export default function Render(
  id: string,
  data: Array<ILine>,
  schema: Record<string, any>,
  bodyModules: Record<string, Function>,
): JSX.Element {
  const { changeSearchOrder } = useSearch();
  const { user } = useAuth();
  function renderColumns(
    line: Record<string, any>,
    schema: Record<string, any>,
  ): Array<JSX.Element> {
    return Object.keys(schema).map((key: string) => {
      const column = schema[key];


      return column.show !== false ? (
        <td>
          {column.includeColumn
            ? (line[column.column] &&
              line[column.column][column.includeColumn]) ||
            ''
            : column?.template ? line?.[column?.column]?.[column?.template] ? line?.[column?.column]?.[column?.template] : '' : typeof line?.[column?.column] === 'string' ? line?.[column?.column] : ''


          }
        </td>
      ) : (
        <></>
      );
    });
  }

  function renderLine(line: Record<string, any>): JSX.Element {
    return (
      <tr>
        <td>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {bodyModules &&
              user && bodyModules.map((lineModule: Function) => lineModule(line))}
          </div>
        </td>
        {renderColumns(line, schema)}
      </tr>
    );
  }

  const render = (): JSX.Element => {
    return (
      <table>
        <thead>
          <tr>
            <th />
            {schema &&
              Object.keys(schema).map((key: string) =>
                schema[key].show !== false ? (
                  <th
                    onClick={() =>
                      changeSearchOrder(id, schema[key].ref, 'asc')
                    }
                  >
                    {schema[key].label}
                  </th>
                ) : (
                  ''
                ),
              )}
          </tr>
        </thead>
        <tbody>{data && data.map(line => renderLine(line))}</tbody>
      </table>
    );
  };

  return render();
}
