import React, { useCallback, useRef, useEffect, useState } from 'react';

import { IconType } from 'react-icons';
import { Feira, Expositor, Container } from './styles';
import { ContactIcons } from '../../components/Menu';
import { urlWebsite } from '../../config';
import api from '../../services/api';
import { useInteraction } from '../../hooks/Interactions';
import { useAuth } from '../../hooks/Auth';
import CheckinQR from '../DashboardPages/features/CheckinQRExpositor';
import Checkins from '../DashboardPages/pages/Checkins';
import CheckinsWithCredentials from '../DashboardPages/pages/CheckinsWithCredentials';
import CredentialPage from '../DashboardPages/pages/CredentialPage';

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

interface IExpositor {
  id: number;
  background: string;
  capa: string;
  title: string;
  link_name: string;
  social: Record<string, any>;
}

const AreaExpositor: React.FC = () => {

  const { user } = useAuth();

  useEffect(() => {

    if (!user || (user.profile !== 9 && user.profile !== 8 && user.profile !== 5)) {

      window.location.href = '/';
    }

  }, [])

  const [currentOption, setCurrentOption] = useState(2);

  const options = (option) => {
    /* expositor */
    if (option === 1) {
      return <Checkins />;
    }
    if (option === 2) {
      return <CheckinQR />;
    }


    return <h2 style={{ margin: '50px auto', background: '#333', color: '#fff', textAlign: 'center', padding: '30px' }}>Acesso não autorizado</h2>
  }

  return (
    <Container>
      <h2 style={{ margin: '50px auto', marginTop: '0px', background: '#333', color: '#fff', textAlign: 'center', padding: '30px' }}>{user?.company}</h2>
      <div className='row gap-sm'><button className='greenButton2' onClick={() => setCurrentOption(2)}>Pesquisar</button>
        <button className='greenButton2' onClick={() => setCurrentOption(1)}>Lista</button>
      </div>
      <div className='column' style={{ alignItems: 'center', width: '100%' }}>
        {options(currentOption)}
      </div>
    </Container >
  );
};
export default AreaExpositor;
