import React from 'react';
import uuid from '../../../core/components/UniqueHash';
import { useModal } from '../../../../../hooks/Modal';

import api from '../../../../../services/api';
import { useToast } from '../../../../../hooks/Toast';
import { useSearch } from '../../../../../hooks/Search';
import { FaEdit, FaUpload } from 'react-icons/fa';
import { Form } from '@unform/web';
import UploadImage from '../../../core/components/Forms/UploadImage';
import Button from '../../../core/components/Forms/Button';



export default function UpdateModule({ lineData }): JSX.Element {
  const { reloadSearchAll } = useSearch();
  const { addModal, removeModal } = useModal();
  const { addToast } = useToast();
  const key = uuid();

  const uploadContacts = async (data) => {

    const formData = new FormData();
    formData.append('file', data.file);



    const dataList = await api.post(`/converter/xlsx-to-json`, formData);





    dataList?.data?.rows?.map((item) => {



    })

    let x = 0;

    while (x < dataList?.data?.rows?.length) {

      const i = dataList?.data?.rows[x];

      await api.post('/cientific-avaliators-dates', { ...i, project_id: lineData?.project_id, })


      addToast({ title: `${x + 1}/${dataList?.data?.rows?.length}`, type: 'info' })
      x += 1;
    }

  }



  const handleUpdate = async (): Promise<void> => {


    const content = (
      <Form onSubmit={uploadContacts}>

        <UploadImage name="file" placeholder='Excel com horários' />
        <Button>Enviar</Button>
      </Form>
    );
    if (content) {
      const keyModal = addModal({ title: 'Atualizar dados', content, key, theme: "whiteModal" });
    }
  };

  const moduleUpdate = (): JSX.Element => (
    <button className="lineIcon" title="Atualizar trabalhos" type="button" onClick={() => handleUpdate()}>
      <FaUpload />
    </button>
  );

  return moduleUpdate();
}
