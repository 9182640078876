import React, { useState } from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container, HeaderButton } from './styles';
import { useSocket } from '../../../../hooks/Socket';
import { Button } from '../../core/components/Forms/Button/styles';
import { FaChartBar, FaQrcode, FaUser } from 'react-icons/fa';

import RecordCheckin from '../../../AreaExpositor/Reporter';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}


const Checkins: React.FC = () => {
  const endpoint = '/checkin-content';
  const title = 'Participantes';
  const formSchema = {

  };


  const formSchemaUpdate = {

  };
  const formValidation: Record<string, ValidationSchema> = {

  };


  const schema: Record<string, any> = {
    id: { ref: 'id', column: 'id', label: 'ID', show: false },
    room_id: { ref: 'room_id', column: 'room_id', label: 'Referência', show: false },
    name: { ref: 'name', column: 'name', label: 'Nome' },
    document_number: { ref: 'document_number', column: 'document_number', label: 'CPF' },
    extra_3: { ref: 'extra_3', column: 'extra_3', label: 'Empresa', show: false },
    extra_4: { ref: 'extra_4', column: 'extra_4', label: 'Telefone', show: false },
    posted_by: {
      ref: 'posted_by',
      column: 'posted_by_response',
      includeColumn: 'email',
      label: 'Leitor', show: false
    },
    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date', show: false
    },
  };

  const config: ISearchComponent = {
    idKey: endpoint,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [

    ],
    bodyModules: [

    ],
  };

  const configVisitors: ISearchComponent = {
    idKey: '/checkin-content-group',
    title,
    endpoint: '/checkin-content-group',
    schema,
    autoStart: true,
    headModules: [

    ],
    bodyModules: [

    ],
  };

  const [currentPage, setCurrentPage] = useState('visitors');



  return (
    <Container>
      <h2 style={{ width: '100%', textAlign: 'center' }}>Participantes</h2>

      {currentPage === 'qrcode' ? <SearchComponent {...config} /> : <></>}
      {currentPage === 'visitors' ? <SearchComponent {...configVisitors} showHeader={false} /> : <></>}
      {currentPage === 'chart' ? <RecordCheckin /> : <></>}
    </Container>
  );
};

export default Checkins;
