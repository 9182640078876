import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';


import { MdError } from 'react-icons/md';

import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../../../../core/components/Forms/Input';
import Button from '../../../../core/components/Forms/Button';
import Loader from '../../../../../../components/Loader';
import MaskedInput from '../../../../core/components/Forms/MaskedInput';
import { ResponseMessage } from '../../../../../../components/ResponseMessage';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import { Error, Separator, Container, FixedResponse } from './styles';
import SelectSimple from '../../../../core/components/Forms/SelectSimple';
import Whatsapp from '../../../../../../components/WhatsAppCompartilhar';

import JsonListMultiple from '../../../../core/components/Forms/JsonListMultiple';

import api from '../../../../../../services/api';

import CheckboxInput from '../../../../core/components/Forms/CheckboxInput';
import { useLanguage } from '../../../../../../hooks/Language';
import TextareaInput from '../../../../core/components/Forms/TextareaInput';
import { areas } from './areas';
import CheckboxIncluder from './components/CategoryChange';

import { useToast } from '../../../../../../hooks/Toast';
import Review from '../../modules/Review';
import { rgb } from 'polished';
import { useConfiguration } from '../../../../../../hooks/Configuration';
import RichText from '../../../../core/components/Forms/RichText';
import { ods } from './ods';
import JsonInputList from '../../../../core/components/Forms/JsonInputList';
import { defaultProjectHash } from '../../../../../../config';
import ArrayInputIncluder from '../../../../core/components/Forms/ArrayInputIncluder';
import { useModal } from '../../../../../../hooks/Modal';
import ReviewOrientatiorApproval from '../../modules/ReviewOrientatorApproval';
import { useAuth } from '../../../../../../hooks/Auth';

interface IRecoverFormData {
  [key: string]: any
}

interface ICaptcha {
  getValue(): string;
}

interface IOption {
  label: string;
  value: string;
  id: string;
}

interface IParam {
  id: string;
  hash: string;
  projectHash: string;
}

const OrientatorApprovalPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [withPassword, setWithPassword] = useState(true);
  const formRef = useRef<FormHandles>(null);
  const { configuration } = useConfiguration();
  const { addModal } = useModal();
  const { id, hash, projectHash = defaultProjectHash } = useParams<IParam>();

  const [submissionData, setSubmissionData] = useState<Record<string, any>>({});
  const [submission, setSubmission] = useState<Record<string, any>>({})
  const recaptchaRef = useRef(null);
  const { translate } = useLanguage();
  const { user } = useAuth();
  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };
  const { addToast } = useToast();

  const [activeReview, setActiveReview] = useState(false);





  const loadPaper = async () => {
    setLoading(true);
    const paper = await api.get(`/get-paper/${id}`);
    setSubmissionData(paper?.data);
    await loadSubmission(paper?.data);
    setLoading(false);


  }

  const loadSubmission = async (paper) => {

    const response = await api.get(`/get-submission-selection-by-id/${projectHash}/${paper?.cientific_selective_id}`);

    if (response?.data) {
      setSubmission(response?.data);

    }

  }

  useEffect(() => {

    if (id) {
      loadPaper();
    }

  }, [id])


  if (!submission?._id || !submissionData?._id) {
    return <Container style={{ width: '100%' }}>

      <div style={{ background: 'rgb(250,100,0)', padding: '20px', margin: '20px auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <strong style={{ width: '100%', textAlign: 'center' }}>Carregando...</strong>

      </div></Container>
  }


  if (submissionData?.orientator_approval === 'Sim') {

    return <Container style={{ width: '100%' }}>
      <a href={user ? '/trabalhos' : '/'} ><button className='greenButton2'> {user ? 'Área de trabalhos' : 'Visitar o site'} </button></a>

      <div style={{ background: 'rgb(0,150,0)', padding: '20px', margin: '20px auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <strong style={{ width: '100%', textAlign: 'center', color: '#fff' }}>Este trabalho já foi aprovado pelo orientador</strong>

      </div></Container>

  }
  if (submissionData?.orientator_approval === 'Não') {

    return <Container style={{ width: '100%' }}>
      <a href={user ? '/trabalhos' : '/'} ><button className='greenButton2'> {user ? 'Área de trabalhos' : 'Visitar o site'} </button></a>
      <div style={{ background: 'rgb(150,0,0)', padding: '20px', margin: '20px auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <strong style={{ width: '100%', textAlign: 'center', color: '#fff' }}>Este trabalho foi reprovado pelo orientador</strong>

      </div></Container>

  }

  if (submission?.allow_avaliate !== 'yes') {

    return <Container style={{ width: '100%' }}>
      <a href={user ? '/trabalhos' : '/'} ><button className='greenButton2'> {user ? 'Área de trabalhos' : 'Visitar o site'} </button></a>

      <div style={{ background: 'orange', padding: '20px', margin: '20px auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <strong style={{ width: '100%', textAlign: 'center', color: '#fff' }}>Etapa de avaliação encerrada</strong>

      </div></Container>

  }
  return (
    <>
      {loading === true && (
        <Loader message="Carregando..." position="fixed" />
      )}
      <Container style={{ width: '100%' }}>






        <ReviewOrientatiorApproval submissionId={id} data={submissionData} submission={submission} />




      </Container>




    </>
  );
};

export default OrientatorApprovalPage;

