import styled from "styled-components";



export const Container = styled.div`

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  background: #fff;
  padding: 40px 40px;
 

  max-width: 800px;
 

.reviewBlock{
     padding: 20px; 
     color: #333; 
     border-left:2px solid rgb(0,150,100); 
     margin-left:10px;
}

strong{
    margin-top: 10px;
    display: block;
}

  >button.close{}

  >h1{
    width: 100%;
    font-size: 24px;
    color: #333;
    margin: 10px auto;
  }

  >h2{
    width: 100%;
    margin-top: 20px;
    font-size: 18px;
    color: rgb(0,100,150);
 
  }

  >strong{
    width: 100%;
    font-size: 16px;
    color: #333;
    margin-top: 10px;
  }

  >p{
    width: 100%;
    font-size: 16px;
    color: #333;
  }

  >button{
    margin: 10px auto;
  }



`;